import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import {
  CreateMenuAdminAuthSessionDocument,
  DestroyMenuAdminAuthSessionDocument,
  FetchIsMenuAdminAuthorizedDocument,
} from "src/gql/__generated__/graphql";

type AuthorizeResultSuccess = {
  status: "success";
};
type AuthorizeResultError = {
  status: "error";
  message: string;
};

type AuthorizeResult = AuthorizeResultSuccess | AuthorizeResultError;

type UnauthorizeResultSuccess = {
  status: "success";
};
type UnauthorizeResultError = {
  status: "error";
  message: string;
};

type UnauthorizeResult = UnauthorizeResultSuccess | UnauthorizeResultError;

type AuthorizeStatus = "authorized" | "unauthorized" | "unknown";

type ContextValue = {
  status: AuthorizeStatus;
  isLoading: boolean;
  check: () => void;
  authorize: (passcode: string) => Promise<AuthorizeResult>;
  unauthorize: () => Promise<UnauthorizeResult>;
};

const AdminAuthContext = createContext<ContextValue>({
  status: "unknown",
  isLoading: false,
  check: () => {
    throw new Error("Not implemented");
  },
  authorize: (): Promise<AuthorizeResult> => {
    throw new Error("Not implemented");
  },
  unauthorize: (): Promise<UnauthorizeResult> => {
    throw new Error("Not implemented");
  },
});

export const AdminAuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [status, setStatus] = useState<AuthorizeStatus>("unknown");
  const [createSession, createSessionResult] = useMutation(
    CreateMenuAdminAuthSessionDocument,
  );
  const [destroySession, destroySessionResult] = useMutation(
    DestroyMenuAdminAuthSessionDocument,
  );
  const [fetch, fetchResult] = useLazyQuery(
    FetchIsMenuAdminAuthorizedDocument,
    {
      fetchPolicy: "cache-first",
      onCompleted: (data) => {
        if (data.isMenuAdminAuthorized) {
          setStatus("authorized");
        } else {
          setStatus("unauthorized");
        }
      },
    },
  );

  const check = useCallback(() => {
    fetch();
  }, [fetch]);

  const authorize = useCallback(
    (passcode: string) => {
      return new Promise<AuthorizeResult>((resolve) => {
        createSession({
          variables: {
            input: {
              passcode,
            },
          },
          onError: (error) => {
            resolve({ status: "error", message: error.message });
          },
        }).then((result) => {
          if (result.data) {
            setStatus("authorized");
            fetchResult.updateQuery(() => ({ isMenuAdminAuthorized: true }));
            resolve({ status: "success" });
            return;
          }

          resolve({ status: "error", message: "認証に失敗しました" });
        });
      });
    },
    [createSession, fetchResult],
  );

  const unauthorize = useCallback(() => {
    return new Promise<UnauthorizeResult>((resolve) => {
      destroySession({
        variables: { input: {} },
        onError: (error) => {
          resolve({ status: "error", message: error.message });
        },
      }).then((result) => {
        if (result.data) {
          setStatus("unauthorized");
          fetchResult.updateQuery(() => ({ isMenuAdminAuthorized: false }));
          resolve({ status: "success" });
          return;
        }

        resolve({ status: "error", message: "認証解除に失敗しました" });
      });
    });
  }, [destroySession, fetchResult]);

  const isLoading =
    createSessionResult.loading ||
    destroySessionResult.loading ||
    fetchResult.loading;

  return (
    <AdminAuthContext.Provider
      value={{ status, isLoading, check, authorize, unauthorize }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};
