import { ChakraProvider, ChakraProviderProps } from "@chakra-ui/react";
import { toastOptions } from "@repo/ui/toast";

import theme from "./theme";

export const MenuChakraProvider = ({ children }: ChakraProviderProps) => {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{ defaultOptions: toastOptions }}
    >
      {children}
    </ChakraProvider>
  );
};
