import { colors as baseColors } from "@repo/ui/colors";
import { merge } from "lodash-es";

const menuColors = {
  // colorSchema 用
  // https://www.tints.dev/primary/171923
  primary: {
    50: "#E6EFEE",
    100: "#CDE0DD",
    200: "#9AC0BA",
    300: "#6BA39A",
    400: "#48746D",
    500: "#29423E",
    600: "#213532",
    700: "#192926",
    800: "#101918",
    900: "#080D0C",
  },

  // 個別カラー
  core: {
    primary: "#29423E",
    primaryInverted: "#F8F6F1",
    accent: "#A19B6E",
  },
  semantic: {
    background: {
      primary: "#F8F6F1", // core.primaryInverted
      inverted: "#29423E", // core.primary
    },
    contents: {
      primary: "#29423E", // core.primary
      secondary: "#7F8A86",
      inverted: "#F8F6F1", // core.primaryInverted
      negative: "#E53E3E", // core.negative
    },
    border: {
      selected: "#29423E", // core.primary
      negative: "#E53E3E", // core.negative
    },
  },
};

export const colors = merge({}, baseColors, menuColors);
