import { extendBaseTheme } from "@chakra-ui/react";
import { speriaTheme } from "@repo/ui/layout/speriaTheme";

import { colors } from "./theme/colors";

const theme = extendBaseTheme(speriaTheme, {
  colors,
  components: {
    Heading: {
      baseStyle: {
        color: "semantic.contents.primary",
      },
    },
    Text: {
      baseStyle: {
        color: "semantic.contents.primary",
      },
    },
  },
});

export default theme;
